import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
import {CookieCustomService} from "../../../../_services/cookie.custom.service";
import {IpServiceService} from "../../../../common/services/ip.service";
import {GeneralService} from "../../../../_services/general.service";

@Component({
  selector: 'app-front-popup',
  templateUrl: './front-popup.component.html',
  styleUrls: ['./front-popup.component.css']
})
export class FrontPopupComponent implements OnInit, OnDestroy {

  ipAddress:string;
  getIp$: any;
  getPopup$: any;
  popupContent: any = undefined;
  isCustom = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private localStorage: CookieCustomService,
    private ip: IpServiceService,
    private generalService: GeneralService
  ) { }

  ngOnInit(): void {
    const isCustom = new Date('2024-04-06 23:59');

    const y = new Date();

    if (y.getTime() < isCustom.getTime()) {
      this.isCustom = true;
    }
    this.getPopup();
  }

  openModal() {
    if (isPlatformBrowser(this.platformId)) {
      const popupModalButton = document.getElementById('popupModalButton') as HTMLElement;
      popupModalButton.click();
    }
  }

  closeModal() {
    if (isPlatformBrowser(this.platformId)) {
      const popupModalButton = document.getElementById('popupModalButton') as HTMLElement;
      popupModalButton.click();
    }
  }

  getIP()
  {
    // this.getIp$ = this.ip.getIPAddress().subscribe((res:any)=>{
    //   this.ipAddress = res.ip;
    //   const date = new Date();
    //   if (this.localStorage.getItem('ipAddressData')) {
    //     const ipAddressData = JSON.parse(this.localStorage.getItem('ipAddressData'));
    //     const ipAddressDataDate = new Date(ipAddressData.date);
    //     if (ipAddressDataDate.getDay() != date.getDay()) {
    //       if (isPlatformBrowser(this.platformId)) {
    //         setTimeout(() => {
    //           this.openModal();
    //         }, 8000);
    //       }
    //       this.localStorage.setItem('ipAddressData', JSON.stringify({date: date.getTime()}));
    //     }
    //   } else {
    //     if (isPlatformBrowser(this.platformId)) {
    //       setTimeout(() => {
    //         this.openModal();
    //       }, 8000);
    //     }
    //     this.localStorage.setItem('ipAddressData', JSON.stringify({date: date.getTime()}));
    //   }
    // });
    const date = new Date();
    if (this.localStorage.getItem('ipAddressData')) {
      const ipAddressData = JSON.parse(this.localStorage.getItem('ipAddressData'));
      const ipAddressDataDate = new Date(ipAddressData.date);
      if (ipAddressDataDate.getDay() != date.getDay()) {
        if (isPlatformBrowser(this.platformId)) {
          setTimeout(() => {
            this.openModal();
          }, 8000);
        }
        this.localStorage.setItem('ipAddressData', JSON.stringify({date: date.getTime()}));
      }
    } else {
      if (isPlatformBrowser(this.platformId)) {
        setTimeout(() => {
          this.openModal();
        }, 8000);
      }
      this.localStorage.setItem('ipAddressData', JSON.stringify({date: date.getTime()}));
    }
  }

  getPopup() {
    this.getPopup$ = this.generalService.getPopup()
      .subscribe(
        (res: any) => {
          if (res.response && res.response.value) {
            if (JSON.parse(res.response.value).status) {
              this.popupContent = JSON.parse(res.response.value);
              this.getIP();
            }
          }
        },
        (error: string | undefined) => {
        }
      );
  }

  getMediaURL(image: any) {
    const fileStorage = this.localStorage.getItem('fileStorage');
    if (image.indexOf('http') > -1) {
      return image;
    } else {
      if (JSON.parse(fileStorage)) {
        return JSON.parse(fileStorage).url + image;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.getIp$) {
      this.getIp$.unsubscribe();
    }
  }

}
